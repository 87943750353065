import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import {
  Box,
  Button,
  Grid,
  Typography,
  Container,
  TextField,
  Modal,
  Fade,
  Backdrop,
  CircularProgress,
  Paper,
  Card,
  CardContent,
  ThemeProvider,
  createTheme,
  alpha,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Dayjs } from "dayjs";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EmailIcon from "@mui/icons-material/Email";
import BusinessIcon from "@mui/icons-material/Business";
import PhoneIcon from "@mui/icons-material/Phone";
import DescriptionIcon from "@mui/icons-material/Description";
import PersonIcon from "@mui/icons-material/Person";
import { motion } from "framer-motion";
import { API_URL } from "../constants";
import dayjs from "dayjs";

interface FacilityAvailability {
  Number: string;
  Monday: boolean;
  Tuesday: boolean;
  Wednesday: boolean;
  Thursday: boolean;
  Friday: boolean;
  Saturday: boolean;
  Sunday: boolean;
  Time: string;
}

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      default: "#f5f5f5",
    },
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: "none",
          fontWeight: 600,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
  },
});

const BookingWidget: React.FC = () => {
  const { wexNumber } = useParams<{ wexNumber: string }>();
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [showSlots, setShowSlots] = useState<boolean>(false);
  const [selectedSlot, setSelectedSlot] = useState<string | null>(null);
  const [availableSlots, setAvailableSlots] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [purpose, setPurpose] = useState<string>("");
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>(null);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [facilityInfo, setFacilityInfo] = useState<any>(null);
  const [facilityLoading, setFacilityLoading] = useState<boolean>(true);
  const [availableDates, setAvailableDates] = useState<Set<string>>(new Set());
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [tourStatus, setTourStatus] = useState<string>("pending");
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [fcs, setFCs] = useState<any[]>([]);
  const [name, setName] = useState<string>("");
  const [facilitySchedule, setFacilitySchedule] = useState<any>(null);
  const [facilityEmail, setFacilityEmail] = useState<string>("");
  const [facilityPhone, setFacilityPhone] = useState<string>("");
  const [facilityAddress, setFacilityAddress] = useState<string>("");
  const [facilityNickname, setFacilityNickname] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    if (wexNumber) {
      fetchFacilityInfo(wexNumber);
      fetchAdditionalFacilityInfo(wexNumber);
    } else {
      console.error("WEx number not provided in URL");
      setFacilityLoading(false);
    }
  }, [wexNumber]);

  const fetchFCs = async () => {
    try {
      const response = await fetch(`${API_URL}/fc/get_all_verified_fcs`);
      const data = await response.json();
      setFCs(data);
    } catch (error) {
      console.error("Error fetching FCs:", error);
    }
  };

  useEffect(() => {
    fetchFCs();
    console.log("FCs:", fcs);
  }, []);

  const createJob = async () => {
    const matchingFC = fcs.find((fc) => fc.wex_ids.includes(wexNumber));
    if (matchingFC) {
      try {
        const jobData = {
          id: matchingFC.id,
          formFacilities: wexNumber,
          formDate: selectedDate?.format("YYYY-MM-DD"),
          formTime: convertTo24Hour(selectedSlot as string), // Convert back to 24-hour format
          formPurpose: "tour",
          formNote: `Company: ${companyName}, Email: ${email}, Phone: ${phoneNumber}, Purpose: ${purpose}`,
          formEstimatedTime: 1,
        };

        const response = await fetch(`${API_URL}/fc/create_job`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(jobData),
        });

        if (!response.ok) {
          throw new Error("Failed to create job");
        }

        console.log("Job created successfully");
      } catch (error) {
        console.error("Error creating job:", error);
      }
    } else {
      console.log("No matching FC found for WEx ID:", wexNumber);
    }
  };

  const fetchFacilityInfo = async (wex_id: string) => {
    try {
      const response = await fetch(`${API_URL}/admin/facility/get`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ wex_id }),
      });
      const data = await response.json();
      setFacilityInfo(data);
      setFacilitySchedule(data.schedule);
      setFacilityEmail(data.email);
      setFacilityPhone(data.phone);
    } catch (error) {
      console.error("Error fetching facility info:", error);
    }
  };

  const fetchAdditionalFacilityInfo = async (wex_id: string) => {
    try {
      const response = await fetch(`${API_URL}/admin/get_facility_info`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ wex_id }),
      });
      const data = await response.json();
      setFacilityAddress(data.address);
      setFacilityNickname(data.nickname);
      setZipCode(data.zip);
      setFacilityLoading(false);
    } catch (error) {
      console.error("Error fetching additional facility info:", error);
      setFacilityLoading(false);
    }
  };

  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
    if (date && wexNumber) {
      setLoading(true);
      fetchAvailableTimes(wexNumber, date)
        .then((slots) => {
          setAvailableSlots(slots);
          setShowSlots(true);
        })
        .catch((error) => {
          console.error("Error fetching available times:", error);
          setAvailableSlots([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const sortTimeSlots = (slots: string[]): string[] => {
    return slots.sort((a, b) => {
      const timeA = new Date(`1970/01/01 ${convertTo24Hour(a)}`);
      const timeB = new Date(`1970/01/01 ${convertTo24Hour(b)}`);
      return timeA.getTime() - timeB.getTime();
    });
  };

  // Add this new function to convert 24h time to AM/PM format
  const convertTo12Hour = (time24: string): string => {
    const [hour, minute] = time24.split(':');
    const hour12 = parseInt(hour, 10) % 12 || 12;
    const amPm = parseInt(hour, 10) >= 12 ? 'PM' : 'AM';
    return `${hour12}:${minute} ${amPm}`;
  };

  // Add this new function to convert AM/PM time to 24h format
  const convertTo24Hour = (time12: string): string => {
    const [time, modifier] = time12.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      hours = (parseInt(hours, 10) + 12).toString();
    }
    return `${hours.padStart(2, '0')}:${minutes}`;
  };

  const fetchAvailableTimes = async (
    wex_id: string,
    date: Dayjs
  ): Promise<string[]> => {
    try {
      const response = await fetch(`${API_URL}/get_available_tour_times`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ wex_id, date: date.format("YYYY-MM-DD") }),
      });
      const data: { [key: string]: boolean } = await response.json();

      const dayOfWeek = date.format("dddd");
      const daySchedule = facilitySchedule[dayOfWeek];

      if (!daySchedule.open) {
        return [];
      }

      const [facilityStart, facilityEnd] = daySchedule.time.split("-");

      // Helper function to convert time string to minutes
      const timeToMinutes = (time: string): number => {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + (minutes || 0);
      };

      const facilityStartMinutes = timeToMinutes(facilityStart);
      const facilityEndMinutes = timeToMinutes(facilityEnd);

      // Filter slots based on facility hours and FC data
      const availableSlots = Object.keys(data).filter((slot) => {
        const slotMinutes = timeToMinutes(slot);

        const isWithinFacilityHours =
          slotMinutes >= facilityStartMinutes &&
          slotMinutes < facilityEndMinutes;

        return isWithinFacilityHours && data[slot];
      });

      // Convert available slots to AM/PM format
      return availableSlots.map(convertTo12Hour);
    } catch (error) {
      console.error("Error fetching available times:", error);
      throw error;
    }
  };

  const handleSlotClick = (slot: string) => {
    setSelectedSlot(slot);
  };

  const AvailabilityTable = () => {
    if (!facilitySchedule) {
      return (
        <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
          Availability information not found for this facility.
        </Typography>
      );
    }

    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Day</TableCell>
            <TableCell>Availability</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {days.map((day) => (
            <TableRow key={day}>
              <TableCell>{day}</TableCell>
              <TableCell>
                {facilitySchedule[day].open
                  ? facilitySchedule[day].time.split('-').map(convertTo12Hour).join(' - ')
                  : "Closed"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    switch (id) {
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        // Clear email error when user starts typing
        if (emailError) setEmailError("");
        // Validate email when user types
        if (value && !isValidEmail(value)) {
          setEmailError("Please enter a valid email address");
        }
        break;
      case "company-name":
        setCompanyName(value);
        break;
      case "phone-number":
        setPhoneNumber(value);
        break;
      case "purpose":
        setPurpose(value);
        break;
    }
  };

  const handleSubmit = () => {
    // Validate email before submission
    if (!isValidEmail(email)) {
      setEmailError("Please enter a valid email address");
      return;
    }

    if (
      !wexNumber ||
      !selectedDate ||
      !selectedSlot ||
      !name ||
      !email ||
      !companyName ||
      !phoneNumber ||
      !purpose ||
      !agreementChecked
    ) {
      alert(
        "Please complete all fields and agree to the terms before submitting.",
      );
      return;
    }

    const result = {
      wex_id: wexNumber,
      date: selectedDate.format("YYYY-MM-DD"),
      time: selectedSlot,
      name,
      email,
      company_name: companyName,
      phone: phoneNumber,
      purpose,
    };

    setFormData(result);
    setShowConfirmation(true);
  };

  const confirmBooking = async () => {
    setIsSubmitting(true);
    try {
      await createTour("accepted");
      await sendEmailAndSMS(formData);
      await createJob();

      setShowSuccess(true);
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
      alert(
        "There was an error submitting your booking. Please try again later.",
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const createTour = async (status: string) => {
    const tourData = {
      ...formData,
      time: convertTo24Hour(formData.time),
      status: status,
    };
    try {
      const createTourResponse = await fetch(`${API_URL}/create_tour`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(tourData),
      });
      
      // Check if the response is not ok
      if (!createTourResponse.ok) {
        // If it's a 409 Conflict (HubSpot contact exists), continue without error
        if (createTourResponse.status === 409) {
          console.log("Contact already exists in HubSpot, continuing with tour creation");
          return;
        }
        // For any other error, throw it
        throw new Error(`Error creating tour: ${createTourResponse.statusText}`);
      }
    } catch (error: any) { // Type the error as any to access message property
      // Only throw error if it's not a 409 Conflict
      if (typeof error?.message === 'string' && !error.message.includes('409')) {
        throw error;
      }
      // If it's a 409 error, just log it and continue
      console.log("Contact already exists in HubSpot, continuing with tour creation");
    }
  };

  const sendEmailAndSMS = async (formData: any) => {
    const emailData = {
      email: facilityEmail,
      phone: facilityPhone,
      tour_email: formData.email,
      tour_company: formData.company_name,
      tour_phone: formData.phone,
      tour_purpose: formData.purpose,
      tour_time: convertTo24Hour(formData.time), // Convert time back to 24-hour format
      tour_date: formData.date,
      wex_id: formData.wex_id,
      tour_name: name,
      facility_address: facilityAddress,
    };

    const sendEmailResponse = await fetch(
      `${API_URL}/send_tour_email_and_sms`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      }
    );
    if (!sendEmailResponse.ok) {
      throw new Error("Failed to send email and SMS notification");
    }
  };

  if (facilityLoading) {
    return (
      <Container
        maxWidth="md"
        sx={{
          mt: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  if (!facilityInfo) {
    return (
      <Container maxWidth="md" sx={{ mt: 5 }}>
        <Typography variant="h5" align="center">
          Facility information not found. Please check the WEx number and try
          again.
        </Typography>
      </Container>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Paper
              elevation={3}
              sx={{
                p: 4,
                borderRadius: 2,
                background: "linear-gradient(145deg, #ffffff 0%, #f0f0f0 100%)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  mb: 4,
                }}
              >
                <Box
                  sx={{
                    width: "20%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src="/logo_black.svg"
                    alt="logo"
                    style={{ width: "60%" }}
                  />
                </Box>
              </Box>
              <Typography
                variant="h4"
                color="primary"
                align="center"
                gutterBottom
                sx={{ fontWeight: 700 }}
              >
                Book a Tour
              </Typography>
              <Typography
                variant="subtitle1"
                align="center"
                gutterBottom
                sx={{ color: "#666" }}
              >
                WEx #: {wexNumber}
              </Typography>
              <Typography
                variant="h6"
                align="center"
                gutterBottom
                sx={{ color: "#333", fontWeight: 600 }}
              >
                {facilityInfo?.name}
              </Typography>
              <Typography
                variant="body1"
                align="center"
                gutterBottom
                sx={{ color: "#666" }}
              >
                {facilityNickname}
                <br />
                Zip Code: {zipCode}
              </Typography>

              <Grid container spacing={3} sx={{ mt: 2 }}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="name"
                    label="Full Name"
                    variant="outlined"
                    value={name}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <PersonIcon sx={{ color: "action.active", mr: 1 }} />
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="email"
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={handleInputChange}
                    error={!!emailError}
                    helperText={emailError}
                    InputProps={{
                      startAdornment: (
                        <EmailIcon sx={{ color: "action.active", mr: 1 }} />
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="company-name"
                    label="Company Name"
                    variant="outlined"
                    value={companyName}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <BusinessIcon sx={{ color: "action.active", mr: 1 }} />
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="phone-number"
                    label="Phone Number"
                    variant="outlined"
                    value={phoneNumber}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <PhoneIcon sx={{ color: "action.active", mr: 1 }} />
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="purpose"
                    fullWidth
                    label="Purpose of the Tour, Sq. Ft. Needed, etc."
                    variant="outlined"
                    value={purpose}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <DescriptionIcon
                          sx={{ color: "action.active", mr: 1 }}
                        />
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  mt: 4,
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <Box
                  sx={{ flex: 1, mr: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 } }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontWeight: 600 }}
                  >
                    Select Date
                  </Typography>
                  <DateCalendar
                    value={selectedDate}
                    onChange={handleDateChange}
                    minDate={dayjs().add(1, 'day')} // Set minimum date to today
                    sx={{
                      "& .MuiPickersDay-root": {
                        borderRadius: "50%",
                      },
                      "& .MuiPickersDay-today": {
                        borderColor: theme.palette.primary.main,
                      },
                      "& .Mui-selected": {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        "&:hover": {
                          backgroundColor: theme.palette.primary.dark,
                        },
                      },
                      "& .MuiPickersDay-dayOutsideMonth": {
                        color: alpha(theme.palette.text.primary, 0.38),
                      },
                    }}
                  />
                </Box>
                <Box sx={{ flex: 1, ml: { xs: 0, md: 2 } }}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontWeight: 600 }}
                  >
                    Select Time
                  </Typography>
                  {loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : availableSlots.length === 0 ? (
                    <Box>
                      <Typography
                        variant="body1"
                        sx={{ color: theme.palette.error.main, mb: 2 }}
                      >
                        No tours are available for this day.
                      </Typography>
                      <Typography variant="body2" sx={{ mb: 1 }}>
                        Normal availability for this facility:
                      </Typography>
                      <AvailabilityTable />
                    </Box>
                  ) : (
                    <Grid container spacing={2}>
                      {sortTimeSlots(availableSlots).map((time, index) => (
                        <Grid item xs={6} key={index}>
                          <Button
                            fullWidth
                            variant={
                              selectedSlot === time ? "contained" : "outlined"
                            }
                            onClick={() => handleSlotClick(time)}
                            sx={{
                              height: "48px",
                              "&.Mui-disabled": {
                                backgroundColor: alpha(
                                  theme.palette.action.disabledBackground,
                                  0.12,
                                ),
                                color: alpha(theme.palette.text.primary, 0.26),
                              },
                            }}
                          >
                            {time}
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Box>
              </Box>
              <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={agreementChecked}
                        onChange={(e) => setAgreementChecked(e.target.checked)}
                        name="agreementChecked"
                      />
                    }
                    label="By scheduling this tour, I acknowledge and accept all Warehouse Exchange Terms & Conditions and Agree that all transactions related to this facility and any other facility listed on Warehouse Exchange will be processed and executed on the platform."
                  />
                </Grid>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  sx={{
                    py: 1.5,
                    px: 4,
                    fontSize: "1rem",
                    boxShadow:
                      "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
                    "&:hover": {
                      boxShadow:
                        "0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)",
                    },
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Paper>
          </motion.div>
        </Container>

        {/* Confirmation Modal */}
        <Modal
          open={showConfirmation}
          onClose={() => setShowConfirmation(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={showConfirmation}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "90%",
                maxWidth: 500,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
              }}
            >
              <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
                Confirm Your Booking
              </Typography>
              <Card
                variant="outlined"
                sx={{
                  mb: 2,
                  backgroundColor: alpha(theme.palette.primary.light, 0.1),
                }}
              >
                <CardContent>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>WEx #:</strong> {formData?.wex_id}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Date:</strong> {formData?.date}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Time Slot:</strong> {formData?.time}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Name:</strong> {formData?.name}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Email:</strong> {formData?.email}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Company Name:</strong> {formData?.company_name}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Phone Number:</strong> {formData?.phone}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Purpose:</strong> {formData?.purpose}
                  </Typography>
                </CardContent>
              </Card>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  onClick={() => setShowConfirmation(false)}
                  variant="outlined"
                  sx={{ px: 3 }}
                >
                  Edit
                </Button>
                <Button
                  onClick={confirmBooking}
                  variant="contained"
                  color="primary"
                  sx={{ px: 3 }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Confirm Booking"
                  )}
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>

        {/* Success Modal */}
        <Modal
          open={showSuccess}
          onClose={() => setShowSuccess(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={showSuccess}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "90%",
                maxWidth: 500,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
              }}
            >
              <motion.div
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Typography
                  variant="h4"
                  align="center"
                  gutterBottom
                  sx={{ color: theme.palette.success.main, fontWeight: 700 }}
                >
                  <CheckCircleIcon
                    sx={{ fontSize: 40, verticalAlign: "middle", mr: 1 }}
                  />
                  Booking Confirmed!
                </Typography>
                <Typography variant="subtitle1" align="center" gutterBottom>
                  Thank you for booking a tour. Here are your details:
                </Typography>
                <Box
                  sx={{
                    mt: 3,
                    backgroundColor: alpha(theme.palette.success.light, 0.1),
                    p: 2,
                    borderRadius: 2,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        <CalendarTodayIcon
                          sx={{
                            verticalAlign: "middle",
                            mr: 1,
                            color: theme.palette.primary.main,
                          }}
                        />
                        <strong>Date:</strong> {formData?.date}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">
                        <AccessTimeIcon
                          sx={{
                            verticalAlign: "middle",
                            mr: 1,
                            color: theme.palette.primary.main,
                          }}
                        />
                        <strong>Time Slot:</strong> {formData?.time}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        <PersonIcon
                          sx={{
                            verticalAlign: "middle",
                            mr: 1,
                            color: theme.palette.primary.main,
                          }}
                        />
                        <strong>Name:</strong> {formData?.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        <EmailIcon
                          sx={{
                            verticalAlign: "middle",
                            mr: 1,
                            color: theme.palette.primary.main,
                          }}
                        />
                        <strong>Email:</strong> {formData?.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        <BusinessIcon
                          sx={{
                            verticalAlign: "middle",
                            mr: 1,
                            color: theme.palette.primary.main,
                          }}
                        />
                        <strong>Company Name:</strong> {formData?.company_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        <PhoneIcon
                          sx={{
                            verticalAlign: "middle",
                            mr: 1,
                            color: theme.palette.primary.main,
                          }}
                        />
                        <strong>Phone Number:</strong> {formData?.phone}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        <DescriptionIcon
                          sx={{
                            verticalAlign: "middle",
                            mr: 1,
                            color: theme.palette.primary.main,
                          }}
                        />
                        <strong>Purpose:</strong> {formData?.purpose}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Typography variant="body2" align="center" sx={{ mt: 2, color: theme.palette.text.secondary }}>
                  A confirmation email has been sent to your provided email address. Please check your spam folder if you don't see it in your inbox.
                </Typography>
                <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setShowSuccess(false);
                      // Reset form state
                      setSelectedDate(null);
                      setSelectedSlot(null);
                      setName("");
                      setEmail("");
                      setCompanyName("");
                      setPhoneNumber("");
                      setPurpose("");
                      setFormData(null);
                      window.location.reload();
                      // redirect back to listing page
                      window.location.replace(`https://warehouseexchange.com/warehouse/${wexNumber}`);
                    }}
                    sx={{ px: 4, py: 1.5 }}
                  >
                    Close
                  </Button>
                </Box>
              </motion.div>
            </Box>
          </Fade>
        </Modal>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default BookingWidget;